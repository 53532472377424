import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion';
import payment from '../../assets/payment.png';
import NewArrival from '../../components/Collections/New Arrival/NewArrival';
import QualityFooter from '../../components/Others/QualityFooter'
import CollectionWithBanner from '../../components/Collections/CollectionWithBanner/CollectionWithBanner'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleData } from '../../store/slices/singleProductslice';
import axios from 'axios';
import { addItem } from '../../store/slices/CartSlice';
const SingleProduct = () => {
    const { name, id } = useParams();
    console.log(id)
    const [product, setProduct] = useState(null);
    const dispatch = useDispatch()
        const Data = [
            {
                id: 1,
                img: "https://media.post.rvohealth.io/wp-content/uploads/2021/05/makhana-1200x628-facebook.jpg",
    
    
                secondImg: "https://t4.ftcdn.net/jpg/04/58/44/43/360_F_458444319_rpRE7D5p6FZQA3NJ2OsjDX2leBWaw4B4.jpg",
                thirdimage: "https://5.imimg.com/data5/SELLER/Default/2020/9/RX/ZA/TX/63235023/5-suta-phool-makhana-flake.jpg",
                fourthImage: "https://staticimg.amarujala.com/assets/images/2019/11/15/makhana_1573801323.jpeg?w=414&dpr=1.0",
                dicountPrice: "Rs.900",
                MainPrice: "Rs.1200",
                // percentage: "25%",
                collectionName: "Makhana",
                description: "Makhana, also known as fox nuts or lotus seeds, are the seeds of the lotus flower plant. They are commonly used in various cuisines, particularly in Indian cuisine, where they are often roasted or fried and seasoned with spices to make a crunchy snack. Makhana is also used in sweets, curries, and other dishes.",
                // color: ["Blue", "Silver", "Black"],
                // SKU: "yoga1",
                availability: "In Stock",
                categories: "Spices",
                // tags: ["Girls' Clothing", "Tops", "Floral Print"]
            },
            {
                id: 2,
                img: "https://media.istockphoto.com/id/965503302/photo/turmeric-powder-and-roots.jpg?s=612x612&w=0&k=20&c=97n0pa3_llc9itGE-nlUJEz5vWwowjA3FF2rNByQmIQ=",
    
    
                secondImg: "https://t3.ftcdn.net/jpg/02/70/09/88/360_F_270098811_CzjDTNmRXoQg78CKORlTe8oJDtR3WVTZ.jpg",
                thirdimage: "https://5.imimg.com/data5/SELLER/Default/2023/7/322144231/HY/VX/KT/42218902/organic-turmeric-powder.jpeg",
                fourthImage: "https://nikura.com/cdn/shop/articles/Turmeric-Root-Oil_11zon_375x235_crop_center.jpg?v=1685717837",
                dicountPrice: "Rs.200",
                MainPrice: "Rs.300",
                // percentage: "20%",
                collectionName: "Turmeric",
                description: "Turmeric, scientifically known as Curcuma longa, is a bright yellow-orange spice commonly used in cooking, particularly in South Asian and Middle Eastern cuisines. It's derived from the root of the Curcuma longa plant and has been used for centuries both as a spice and as a medicinal herb.",
                // color: ["Pink", "White", "Yellow"],
                // SKU: "yoga2",
                availability: "In Stock",
                categories: "Spices",
                // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
            },
            {
                id: 3,
                img: "https://5.imimg.com/data5/SELLER/Default/2023/4/297114051/WN/SZ/QF/109200036/red-chilli-powder.jpeg",
    
    
                secondImg: "https://5.imimg.com/data5/SELLER/Default/2022/12/JC/ZZ/SO/6299242/red-chilli-powder.jpg",
                thirdimage: "https://static.toiimg.com/photo/63530651.cms",
                fourthImage: "https://spicerackindia.com/wp-content/uploads/2020/11/Byadagi-Chilli-Powder-FloraFoods.jpg",
                dicountPrice: "Rs.100",
                MainPrice: "Rs.200",
                // percentage: "30%",
                collectionName: "Chilli Powder",
                description: "Chili powder is a spice blend made from dried chili peppers that are ground into a fine powder. It's a staple ingredient in many cuisines around the world, particularly in Mexican, Indian, and Thai cooking, where it adds heat, depth of flavor, and vibrant color to dishes.",
                // color: ["Denim Blue"],
                // SKU: "yoga3",
                availability: "In Stock",
                categories: "Spices",
                // tags: ["Girls' Clothing", "Bottoms", "Denim Shorts"]
            },
            {
                id: 4,
                img: "https://m.media-amazon.com/images/I/41JhW9gc0zL._AC_UF1000,1000_QL80_.jpg",
                secondImg: "https://freshclub.co.in/cdn/shop/products/corinader-powder.jpg?v=1660189230",
                thirdimage: "https://hetha.in/cdn/shop/products/coriandert-powder-1_1200x1200.jpg?v=1689956209",
                fourthImage: "https://t3.ftcdn.net/jpg/02/28/74/20/360_F_228742060_zu2eCoQvnYAcI96p2bOW9tpuDzOD2TRs.jpg",
                dicountPrice: "Rs.80",
                MainPrice: "Rs.150",
                // percentage: "45%",
                collectionName: "Coriander Powder",
                description: "Coriander powder, also known as ground coriander or coriander seed powder, is a spice made from dried and ground coriander seeds. Coriander is derived from the Coriandrum sativum plant, which is part of the parsley family. Both the seeds and leaves of the coriander plant are used in cooking, but coriander powder specifically refers to the ground seeds.",
                // color: ["Pink", "Purple", "White"],
                // SKU: "yoga4",
                availability: "In Stock",
                // categories: "Girls' Fashion",
                // tags: ["Girls' Accessories", "Headbands", "Bow Design"]
            },
            {
                id: 5,
                img: "https://cdn.britannica.com/59/219359-050-662D86EA/Cumin-Spice.jpg",
    
    
                secondImg: "https://5.imimg.com/data5/SELLER/Default/2021/10/XZ/IW/LO/3042133/cumin-seed.jpg",
                thirdimage: "https://cookbook.pfeiffer.net.au/files/cuminSeeds-scaled.jpg",
                fourthImage: "https://static.toiimg.com/thumb/resizemode-4,width-1280,height-720,msid-8366927/8366927.jpg",
                dicountPrice: "Rs.200",
                MainPrice: "Rs.300",
                // percentage: "25%",
                collectionName: "Cumin Seeds",
                description: "Cumin seeds, derived from the Cuminum cyminum plant, are a popular spice known for their warm, earthy flavor and aromatic qualities. They are native to the Mediterranean region and have been used in cooking for thousands of years, dating back to ancient civilizations such as the Egyptians, Greeks, and Romans.",
                // color: ["Blue", "Silver", "Black"],
                // SKU: "yoga1",
                availability: "In Stock",
                categories: "Spices",
                // tags: ["Girls' Clothing", "Tops", "Floral Print"]
            },
            {
                id: 6,
                img: "https://www.amar-khamar.com/cdn/shop/products/BlackMustard2.jpg?v=1637861957",
    
    
                secondImg: "https://5.imimg.com/data5/SELLER/Default/2022/1/JK/BJ/EI/8491760/mustard-seeds.jpg",
                thirdimage: "https://m.media-amazon.com/images/I/51coKUr87JL._AC_UF1000,1000_QL80_.jpg",
                fourthImage: "https://ricedesk.com/product-images/Mustard-Seeds-Small.jpg/788104000002905349/700x700",
                dicountPrice: "$25",
                MainPrice: "Rs.500",
                percentage: "Rs.600",
                collectionName: "Mustard Seeds",
                description: "Mustard seeds are small, round seeds derived from the mustard plant, which belongs to the Brassicaceae family. These seeds come in different varieties, such as yellow (also known as white), brown, and black, each with its own distinct flavor and culinary uses.",
                // color: ["Pink", "White", "Yellow"],
                // SKU: "yoga2",
                availability: "In Stock",
                categories: "Spices",
                // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
            },
            {
                id: 7,
                img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-1_1.jpg?v=1700194998&width=600",
    
    
                secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-2_1.jpg?v=1700194998&width=600",
                thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-3_1.jpg?v=1700194998&width=600",
                fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-4_1.jpg?v=1700194998&width=600",
                dicountPrice: "$15",
                MainPrice: "$28",
                percentage: "30%",
                collectionName: "Bottoms",
                description: "Add a touch of style to her wardrobe with our Girls' Denim Shorts. These classic denim shorts feature a versatile design that pairs effortlessly with a variety of tops and shoes. The soft and stretchy fabric ensures a comfortable fit, while the adjustable waistband allows for a customizable fit as she grows. Perfect for everyday wear or casual outings, these denim shorts will become a staple in her warm-weather wardrobe.",
                color: ["Denim Blue"],
                SKU: "yoga3",
                availability: "In Stock",
                categories: "Girls' Fashion",
                tags: ["Girls' Clothing", "Bottoms", "Denim Shorts"]
            },
            {
                id: 8,
                img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-1.jpg?v=1700203068&width=600",
                secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-2.jpg?v=1700203068&width=600",
                thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-3.jpg?v=1700203068&width=600",
                fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-4.jpg?v=1700203068&width=600",
                dicountPrice: "$10",
                MainPrice: "$18",
                percentage: "45%",
                collectionName: "Accessories",
                description: "Complete her look with our Girls' Bow Headband Set. This set includes three adorable headbands, each featuring a different bow design for versatile styling options. Made from soft and stretchy fabric, these headbands are gentle on her hair and comfortable to wear all day long. Whether she's dressing up for a special occasion or adding a cute accent to her everyday outfit, these bow headbands are the perfect accessory.",
                color: ["Pink", "Purple", "White"],
                SKU: "yoga4",
                availability: "In Stock",
                categories: "Girls' Fashion",
                tags: ["Girls' Accessories", "Headbands", "Bow Design"]
            },
            {
                id: 9,
                img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-1_1.jpg?v=1700193822&width=600",
    
    
                secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-2_1.jpg?v=1700193822&width=600",
                thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-3_1.jpg?v=1700193822&width=600",
                fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-4_1.jpg?v=1700193822&width=600",
                dicountPrice: "$20",
                MainPrice: "$30",
                percentage: "25%",
                collectionName: "Tops",
                description: "Keep your little one stylish and comfortable with our Girls' Floral Print Tee. Crafted from soft and breathable cotton fabric, this tee features a vibrant floral print that adds a pop of color to her everyday look. The relaxed fit and crew neckline ensure all-day comfort, while the short sleeves make it perfect for layering or wearing alone during warmer weather. Pair it with jeans, shorts, or a skirt for versatile styling options.",
                color: ["Blue", "Silver", "Black"],
                SKU: "yoga1",
                availability: "In Stock",
                categories: "Girls' Fashion",
                tags: ["Girls' Clothing", "Tops", "Floral Print"]
            },
            {
                id: 10,
                img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-1_1.jpg?v=1700194727&width=600",
    
    
                secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-2.jpg?v=1700194728&width=600",
                thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-3_1.jpg?v=1700194727&width=600",
                fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-4_1.jpg?v=1700194727&width=600",
                dicountPrice: "$25",
                MainPrice: "$40",
                percentage: "20%",
                collectionName: "Matching Sets",
                description: "Elevate her everyday wardrobe with our Girls' Floral Matching Set. This set includes a comfortable short-sleeve top and coordinating shorts, both adorned with a vibrant floral print for a playful touch. The soft and stretchy fabric ensures all-day comfort and freedom of movement, while the elastic waistband on the shorts provides a secure fit. Mix and match these pieces with other items in her closet for versatile styling options.",
                color: ["Pink", "White", "Yellow"],
                SKU: "yoga2",
                availability: "In Stock",
                categories: "Girls' Fashion",
                tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
            }
    
    
        ];
        useEffect(() => {
            const filteredData = Data.find(item => item.id === parseInt(id));
            setProduct(filteredData);
        }, [id]);
        

    useEffect(() => {

        window.scrollTo({
            top: "0",
            behavior: "smooth"
        })

    }, [id]);
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    const [sizesPrize, setSizePrize] = useState()
    const [sizesDPrize, setDSizePrize] = useState()

    const [selectedColorIndex, setSelectedColorIndex] = useState(null);
    const [selectedColor, setSelectedColor] = useState("");

    const handleClickColor = (index, color) => {
        setSelectedColorIndex(index);
        setSelectedColor(color);

    };

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);
    const handleSmallImageClick = (imageSrc) => {
        setProduct({
            ...product,
            img: imageSrc
        });
    };
    const handleClickSizes = (sizes) => {
        // console.log(sizes.)
        setSizePrize(sizes)
        setDSizePrize(sizes.discountPrice)
    }
    const [quantity, setQuantity] = useState(1);

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const totalStock = 500; // Assuming total stock is 500 units
    const currentStock = sizesPrize ? sizesPrize.colors.stockNo || 0 : 0; // Current stock quantity
    const remainingStock = Math.max(0, totalStock - currentStock); // Calculate remaining stock
    const percentageRemaining = (remainingStock / totalStock) * 100;

    const handleAddToCartClick = () => {
        if (!sizesPrize) {
            // If no size is selected, default to the first size
            const selectedSize = product.sizes[0];
            handleAddToCart(selectedSize, selectedColorIndex || 0); // Provide default value for color index
        } else {
            // If size is selected, call handleAddToCart with selected size and color
            handleAddToCart(sizesPrize, selectedColorIndex || 0); // Provide default value for color index
        }
    };

    const handleAddToCart = (selectedSize, selectedColorIndex) => {
        // Construct the product object with selected size, color, and quantity

        const selectedProduct = {
            ...product,
            size: selectedSize.size,
            price:sizesDPrize,
            color: selectedColor || selectedSize.colors.colorValue,
            quantity: quantity // Assuming quantity is already set elsewhere in your code
        };

        // Dispatch addItem action with the selected product to add to cart
        dispatch(addItem(selectedProduct));
        // Optionally, you can show a success message or perform any other action after adding to cart
        console.log('Product added to cart:', selectedProduct);
    };



    return (
        <div className='w-full mt-5'>

            {/* Bread Crumbs */}
            <nav className="flex bg-gray-200 py-4" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                            </svg>
                            Home
                        </a>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <a href="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Projects</a>
                        </div>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{product ? product.collectionName || "s" : ""}</span>
                        </div>
                    </li>
                </ol>
            </nav>

            {/* main-single-product */}
            <div className='max-w-screen-xl  mx-auto py-3 px-3 min-h-screen'>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div className=' w-full md:w-[600px]  h-[600px] flex md:space-x-3 '>
                        <div className='hidden md:block small-imgs mt-2 px-2'>
                            <div className='w-32 mb-2 cursor-pointer h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.img : "")} src={product ? product.img : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>

                            <div className='w-32 mb-2 cursor-pointer h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.secondImg : "")} src={product ? product.secondImg : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                            <div className='w-32 mb-2 cursor-pointer h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.thirdImage : "")} src={product ? product.thirdImage : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                            <div className='w-32 mb-2 cursor-pointer h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.fourthImage : "")} src={product ? product.fourthImage : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                        </div>
                        <motion.div

                            whileTap={{ scale: 0.9 }} className='w-full relative bigimage h-[600px]'>
                            <img src={product ? product.img : ""} className='w-full h-full object-cover object-center' alt="" />
                            <div className='tag'>
                                {product ? product.percentage : ""}
                            </div>
                        </motion.div>

                    </div>
                    <div className=' md:hidden flex gap-2 small-imgs mt-2 px-2'>
                        <div className=' w-[4rem] h-[4rem] md:w-32 mb-2 cursor-pointer md:h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.img : "")} src={product ? product.img : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                        <div className=' w-[4rem] h-[4rem] md:w-32 mb-2 cursor-pointer md:h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.secondImg : "")} src={product ? product.secondImg : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                        <div className=' w-[4rem] h-[4rem] md:w-32 mb-2 cursor-pointer md:h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.thirdImage : "")} src={product ? product.thirdImage : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                        <div className=' w-[4rem] h-[4rem] md:w-32 mb-2 cursor-pointer md:h-[8.8rem]'><img onError={(e) => e.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} onMouseEnter={() => handleSmallImageClick(product ? product.fourthImage : "")} src={product ? product.fourthImage : ""} className='w-full mb-2 h-full object-cover' alt="" /></div>
                    </div>
                    <div className='w-full'>
                        <div className=' min-h-screen'>
                            <p className='text-4xl font-medium mb-2'>{product ? product.productName : ""}</p>
                            <h3 className='text-2xl mt-6 font-bold text-red-500'>
                                <span className='text-gray-600'>Offer Price : </span>
                                {/* <del className='text-gray-500 font-medium'>{sizesPrize ? sizesPrize.mainPrice || product && product.sizes[0].mainPrice : product && product.sizes[0].mainPrice}</del> {sizesPrize ? (sizesPrize.discountPrice ? sizesPrize.discountPrice : product && product.sizes[0].discountPrice) : product && product.sizes[0].discountPrice} */}
                            </h3>
                            <div className='w-full '>
                                <p className='text-lg text-pretty mt-4'>{product ? product.description : ""}</p>
                            </div>
                            <div className='stock-bar w-full mt-5'>
                                {/* <h2 className='font-bold text-2xl'>HURRY! ONLY {sizesPrize ? sizesPrize.colors.stockNo || product && product.sizes[0].colors.stockNo : product && product.sizes[0].colors.stockNo} LEFT IN STOCK.</h2> */}
                                {/* <motion.div className="w-full bg-gray-200 mt-5 rounded-full h-2.5 dark:bg-gray-700">
                                    <motion.div
                                        className="bg-[#F88180] h-2.5 rounded-full"
                                        style={{ width: `${percentageRemaining - 10}%` }} // Set the width dynamically
                                    ></motion.div>
                                </motion.div> */}
                            </div>
                            {/* <div className='sale-counter mt-2'>
                                <p className='text-xl text-pretty'>Hurry up! Sale Ends in</p>
                            </div> */}
                            <div className='mt-2'>
                                <h2 className='text-xl font-medium '>Available Colour</h2>
                                <ul className='flex items-center'>
                                    {product && product.sizes && product.sizes.map((sizeItem, index) => (
                                        <li className='m-2 text-xl font-bold text-gray-900' key={index}>
                                            <span
                                                onClick={() => handleClickColor(index, sizeItem.colors.colorValue)}
                                                className={`inline-block w-6 h-6 mr-2 cursor-pointer rounded-full ${selectedColorIndex === index ? 'outline' : ''}`}
                                                style={{ backgroundColor: sizeItem.colors.colorValue }}
                                            ></span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* <div className='sizes flex items-start  gap-3 mt-2'>
                                {product && product.sizes.map((sizes, index) => (
                                    <div onClick={() => handleClickSizes(sizes)} className='bg-[#9680A6] rounded-[10px] cursor-pointer py-1 px-5 text-white' key={index}>{sizes.size}</div>
                                ))}
                            </div> */}
                            <div className='flex  mt-5 items-start justify-between'>
                                <div className="flex justify-between border-2 w-[40%]  rounded-[40px] border-black items-center">
                                    <button onClick={handleDecrement} className="  font-extrabold text-lg text-gray-900 px-5 py-1 rounded-md ml-2">-</button>
                                    <span className='text-lg font-bold'>{quantity}</span>
                                    <button onClick={handleIncrement} className="  font-extrabold text-lg text-gray-900 px-5 py-1 rounded-md mr-2">+</button>
                                </div>
                                <div className="flex border-2 w-[100%] rounded-[40px] border-white items-center">
                                    <button onClick={handleAddToCartClick} className='py-2 w-full rounded-[40px] bg-gray-900 text-white'>Add To Cart</button>
                                </div>
                            </div>
                            <div className="otherdetails mt-6">
                                <table className="border-collapse text-left border border-gray-300">
                                    <tbody>
                                        <tr className="border-b border-gray-300">
                                            <th className="border-r w-52 border-gray-300 text-gray-600 capitalize text-xl py-2 px-4">SKU:</th>
                                            <td className="py-2 px-4  w-52 text-gray-900 capitalize text-xl">{product && product.SKU ? product.SKU : ""}</td>
                                        </tr>
                                        <tr className="border-b border-gray-300">
                                            <th className="border-r  w-52 border-gray-300 text-gray-600 capitalize text-xl py-2 px-4">Availability:</th>
                                            <td className="py-2 px-4  w-52 text-gray-900 capitalize text-xl">{product && product.availability ? "In Stock" : "Out Of Stock"}</td>
                                        </tr>
                                        <tr className="border-b border-gray-300">
                                            <th className="border-r  w-52 border-gray-300 text-gray-600 capitalize text-xl py-2 px-4">Categories:</th>
                                            <td className="py-2 px-4  w-52 text-gray-900 capitalize text-xl">{product && product.categories ? product.categories : ""}</td>
                                        </tr>
                                        <tr>
                                            <th className="border-r  w-52 border-gray-300 text-gray-600 capitalize text-xl py-2 px-4">Tags:</th>
                                            <td className="py-2 px-4  w-52 text-gray-900 capitalize text-xl">{product && product.tags && product.tags.length > 0 ? product.tags : "N.A"}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div className='payment-foot w-[100%] '>
                                <img src={payment} className='w-full object-cover object-center' alt="" />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {/* products */}
            <CollectionWithBanner />
            <NewArrival />
            <QualityFooter />

        </div>
    )
}

export default SingleProduct