import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (


        <footer className="bg-white border-t mt-5 dark:bg-gray-900">
            <div className="mx-auto w-full p-5 ">
                <div className="grid grid-cols-1 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">About</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            {/* <li className="mb-4">
                                <a href="#" className="hover:underline">About Us
                                </a>
                            </li> */}
                            <p>VARP is a dynamic import-export company specializing in the trade of clothing and spices. With a commitment to quality, reliability, and customer satisfaction, we strive to connect buyers and sellers across borders, facilitating seamless transactions and fostering long-term partnerships.</p>
                            {/* <p className='mt-2 '> <b className='text-black'>Adderss :</b> G-191 Prashant Vihar Sector 14, Near By Indian Petrol Pump Outer Ring Road, Delhi -110085</p> */}
                            {/* <p className='mt-1 whitespace-nowrap'><b className='text-black'>Contact Details :</b> +91- 9910180546 | 9717139144</p> */}
                            {/* <li className="mb-4">
                    <a href="#" className="hover:underline">Contact Us</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">Store Location</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">FAQ</a>
                </li> */}
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Categories</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4">
                                <Link to={'/Spices'} className=" hover:underline">Spices</Link>
                            </li>
                            <li className="mb-4">
                                <Link to={'/Cloths'} className="hover:underline">Cloths</Link>
                            </li>
                            {/* <li className="mb-4">
                                <a href="#" className="hover:underline">Clothing</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline">Dress</a>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Information</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4">
                                <a href="/Sign-up" className="hover:underline">Register</a>
                            </li>
                            <li className="mb-4">
                                <a href="Sign-in" className="hover:underline">Login</a>
                            </li>
                            <li className="mb-4">
                                <a href="/Shopping-Cart" className="hover:underline">My Cart</a>
                            </li>
                            <li className="mb-4">
                                <a href="#" className="hover:underline">Contact Us</a>
                            </li>
                        </ul>
                    </div> */}

                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Services</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4">
                                <a href="/Terms-And-Conditions" className="hover:underline">Terms And Condition</a>
                            </li>
                            <li className="mb-4">
                                <a href="/Return-And-Refund-Policy" className="hover:underline">Return & Refund</a>
                            </li>
                            <li className="mb-4">
                                <a href="/Shipping-Policy" className="hover:underline">Shipping-Policy</a>
                            </li>
                            <li className="mb-4">
                                <a href="/Certificates" className="hover:underline">Certificates</a>
                            </li>
                            {/* <li className="mb-4">
                    <a href="#" className="hover:underline">Terms &amp; Conditions</a>
                </li> */}
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Information</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4">
                                <a href='tel:+91 9667163337' className=""><i class="ri-phone-line"></i> 9667163337</a>
                            </li>
                            <li className="mb-4">
                                <a href='mailto:ak40468@gmail.com' className=""><i class="ri-mail-line"></i> info@varpexpo.com</a>
                            </li>
                            {/* <li className="mb-4">
                                <a  className=""><i class="ri-map-pin-line"></i> Near Maan Property, Deep Vihar, C1/67 Rohini sector-24, pin code :- 110085
</a>
                            </li> */}
                            {/* <li className="mb-4">
                                <a  className="hover:underline">Contact Us</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="px-4 py-6 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">© 2024 <a href="">VARP</a>. All Rights Reserved.
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">Design By <a href="dgmt.in">DGMT.IN</a>. 
                    </span>
                    <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                        <a href="https://www.facebook.com/profile.php?id=61559717151308" target='_blank' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
                            </svg>
                            <span className="sr-only">Facebook page</span>
                        </a>
                        <a href="https://www.instagram.com/varp_export/" target='_blank' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30">
                                <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                            </svg>
                            <span className="sr-only">Instagram</span>
                        </a>
                        <a href="https://twitter.com/varp_export" target='_blank' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                <path fill-rule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clip-rule="evenodd" />
                            </svg>
                            <span className="sr-only">Twitter page</span>
                        </a>
                        <a href="https://www.linkedin.com/company/102902121/admin/feed/posts/?feedType=following" target='_blank' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 30">
                                <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
                            </svg>
                            <span className="sr-only">Linkdin</span>
                        </a>
                        {/* <a href="#" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z" clip-rule="evenodd" />
                            </svg>
                            <span className="sr-only">Dribbble account</span>
                        </a> */}
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer