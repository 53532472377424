import React, { useEffect, useState } from 'react'
import QuickView from '../New Arrival/QucikView'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../store/slices/product.slice';
import { Link } from 'react-router-dom';
const WeeksHighlight = () => {

    const [showQuickView, setShowQucikView] = useState(false)
    const [items, setItems] = useState(false)

    const handleShowQuickView = (item, index) => {
        setShowQucikView(true)
        console.log("Item", item)
        console.log("index", index)
        setItems(item)

    }
    const handleClose = () => {
        setShowQucikView(false)
    }

    const Data = [
        {
            id: 1,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDsl7Al_RCrE_xI2PhiKiTmM-yCy92FG42fg&s",


            secondImg: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfs18IbjcpDWoFTGAmxWnjXlNyJuIo8J-XOg&s",
            thirdimage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeqMn7tKBOkKSXVWBX4BDbVq0Uff2RCsfRrg&s",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxs6Oe9Ef38yHJ-zaukbsWlFwKcDfjCQ1_Pg&s",
            dicountPrice: "Rs.1200",
            MainPrice: "Rs.1600",
            percentage: "25%",
            collectionName: "Leather Jacket",
            description: "A leather jacket is more than just a piece of clothing; it's a timeless fashion staple that exudes style, attitude, and versatility. Crafted from high-quality leather, these jackets have been a symbol of rebellion, ruggedness, and sophistication for decades, making them a wardrobe essential for both men and women.",
            // color: ["Blue", "Silver", "Black"],
            // SKU: "yoga1",
            availability: "In Stock",
            categories: "Men Leather Jacket",
            // tags: ["Girls' Clothing", "Tops", "Floral Print"]
        },
        {
            id: 2,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxl5iqL4PCqnhVU7jOucbtWAgskR3XxF8cYQ&s",


            secondImg: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc8BmQNTq25DL2IwRrRQB-vuLeL8Xpt-UC6A&s",
            thirdimage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwIjg1SkxTWyVJccYczIdqn_nKvZT4ztXVqQ&s",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQej-ghWXX_PvBtxlqWn9o0UjZXtc9DL87s4A&s",
            dicountPrice: "Rs.500",
            MainPrice: "Rs.1000",
            percentage: "20%",
            collectionName: "Leather Belt",
            description: "A men's belt is a fundamental accessory that serves both a practical and aesthetic purpose. Beyond simply holding up trousers, a belt can add flair to an outfit, create a focal point, and enhance the overall style. From classic leather belts to modern fabric options, there's a wide range of styles, materials, and designs to suit every taste and occasion.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Leather Belt",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 3,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC814fceB_gC7EKs0tuwc_WvR_q1LH97kdHQ&s",


            secondImg: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTTEt3QklNcHt8VZCuuAv5p3kNxMlzUr3HWA&s",
            thirdimage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT36LtqEKqTEi_UO1s_FqH_3q7yzFx6WRe-lA&s",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScPoQnPunmOWtWVsGbyUMPpvdZ3DI9-TLEcw&s",
            dicountPrice: "Rs.2000",
            MainPrice: "Rs.3000",
            percentage: "20%",
            collectionName: "Leather Jacket",
            description: "Perfume, a fragrant liquid typically made from a mixture of essential oils, aroma compounds, solvents, and fixatives, is a product that has captivated human senses for centuries. It's not just about smelling nice; perfume is an art form, a form of self-expression, and a way to evoke emotions and memories.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Leather Jacket for girl",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 4,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv13v9j0KmST09FbMQpK0MJKPOLb9FxBlcVg&s",


            secondImg: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqj-DPPr8tmLRQvPH8LgbpaBiHRdSJEb4H9g&s",
            thirdimage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSawj5VYDSWPNTsO24eLKEhUx_haBx5hJXflA&s",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv13v9j0KmST09FbMQpK0MJKPOLb9FxBlcVg&s",
            dicountPrice: "Rs.1400",
            MainPrice: "Rs.3000",
            percentage: "20%",
            collectionName: "leather bag",
            description: "A leather bag is a luxurious and practical accessory that combines style, durability, and functionality. Crafted from high-quality leather, these bags are not only timeless fashion statements but also reliable companions for everyday use, travel, and special occasions. From classic to contemporary designs, leather bags come in a variety of styles, shapes, and sizes to suit different preferences and needs.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "leather bag",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 5,
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSQtUV1PNZuI07YP5h8dmE-EhJe8yv1EbzHw&s",


            secondImg: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6lCUmScoRx39PFPCbFQ9zuqRBkKB_WTy6Tw&s",
            thirdimage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4NpTFAHF4_kWOTs8qOlz08oc49sOGcn39Bw&s",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1AVALjcipIsWC9fFPikp9bco-ShKhB4mNvw&s",
            dicountPrice: "Rs.1000",
            MainPrice: "Rs.2000",
            percentage: "20%",
            collectionName: "leather purse",
            description: "A leather purse is a luxurious and practical accessory that serves as a stylish way to carry essentials such as money, cards, keys, and cosmetics. Crafted from high-quality leather, these purses come in a variety of styles, sizes, and designs to suit different tastes and needs. From classic to contemporary, leather purses are versatile accessories that add sophistication and elegance to any outfit.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "leather purse",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
    ];


    return (
        <div className='w-full mt-12'>
            <div className='text-center py-2 md:py-5'>
                <h1 className='md:text-3xl text-pretty mb-2 text-2xl font-bold'>Leather Products</h1>
                <p className='md:tracking-wide font-meduim text-base md:text-xl	'>Shop the trending products and most buy of the week</p>
            </div>
            <div className='mt-5 p-2'>
    <div className='w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 space-x-3 gap-2'>
        {Data && Data.map((item, index) => (
            // Corrected condition here
            <div className='p-1 md:p-2' key={index}>
                <div className='relative first-img '>
                    <Link className='relative first-img' >
                        <img src={item.img} className='w-full relative transition-all duration-500 ease-linear h-64 md:h-[23rem] object-cover object-center' alt={item.collectionName} />
                        <img src={item.secondImg} className='w-full transition-all duration-500 ease-linear second-img absolute top-0 opacity-0 h-64 md:h-[23rem] object-cover object-center' alt={item.secondImg} />
                    </Link>
                    <div className='buttons'>
                        <ul>
                            <li onClick={() => handleShowQuickView(item, index)}><i className="ri-eye-fill"></i></li>
                            <li><i className="ri-shopping-bag-4-line"></i></li>
                            <li><i className="ri-heart-fill"></i></li>
                        </ul>
                    </div>
                    <div className='product-information text-center'>
                        <p className='text-xl font-medium mb-2'>{item.collectionName}</p>
                        <div className='prices'>
                                        <p className='text-base font-semibold text-[#E51515]'><del className='text-gray-500'>{item.MainPrice}</del> {item.dicountPrice}</p>
                                    </div>
                    </div>
                    <div className='tag'>
                        {item.percentage}
                    </div>
                </div>
            </div>
        ))}
    </div>
</div>

            <QuickView showQuickView={showQuickView} handleClose={handleClose} item={items} />
        </div>)
}

export default WeeksHighlight