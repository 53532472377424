import React, { useEffect, useState } from 'react'
import QuickView from '../New Arrival/QucikView'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../store/slices/product.slice';
import { Link } from 'react-router-dom';
const WeeksHighlight = () => {

    const [showQuickView, setShowQucikView] = useState(false)
    const [items, setItems] = useState(false)

    const handleShowQuickView = (item, index) => {
        setShowQucikView(true)
        console.log("Item", item)
        console.log("index", index)
        setItems(item)

    }
    const handleClose = () => {
        setShowQucikView(false)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const Data = [
        {
            id: 1,
            img: "https://www.beyours.in/cdn/shop/products/black-tshirt-model-3.jpg?v=1681800504",


            secondImg: "https://i.pinimg.com/736x/62/98/b0/6298b026a65cf80bcf9dce061e9b79c9.jpg",
            thirdimage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNkT7JMxrWBCnysjCAkMMdJh9HSnT5QQG4mRXfeVeQsTRxdRz6XgPsZUNf2q_5uT2PP5k&usqp=CAU",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0fXJP2jnT_ZmIrFeOmkto8WuI_CloeCJKVbooHEvf3Uj1oVwcX7JQ6pr1G0uaGZYy2EE&usqp=CAU",
            dicountPrice: "Rs.400",
            MainPrice: "Rs.500",
            percentage: "25%",
            collectionName: "T-Shirt",
            description: "The black t-shirt, a timeless staple of wardrobes worldwide, holds a unique allure that transcends trends and seasons. It's not just a piece of clothing; it's a canvas for individual expression, a symbol of versatility, and an embodiment of simplicity.",
            // color: ["Blue", "Silver", "Black"],
            // SKU: "yoga1",
            availability: "In Stock",
            categories: "Cloths",
            // tags: ["Girls' Clothing", "Tops", "Floral Print"]
        },
        {
            id: 2,
            img: "https://m.media-amazon.com/images/I/519frzdRRpL._AC_UY1100_.jpg",


            secondImg: "https://assets.ajio.com/medias/sys_master/root/20240228/arEs/65dee6b516fd2c6e6a256e21/-473Wx593H-467113561-black-MODEL.jpg",
            thirdimage: "https://assets.ajio.com/medias/sys_master/root/20231016/Zvrr/652c442addf77915193e7a60/-473Wx593H-466707850-black-MODEL.jpg",
            fourthImage: "https://assets.ajio.com/medias/sys_master/root/20231216/9ARV/657cfba5afa4cf41f5d59e76/-473Wx593H-466890785-black-MODEL5.jpg",
            dicountPrice: "Rs.600",
            MainPrice: "Rs.800",
            percentage: "20%",
            collectionName: "Shirt",
            description: "The black shirt, an essential garment in any wardrobe, embodies understated elegance and versatility. With its sleek and timeless appeal, it seamlessly transitions from casual to formal settings, making it a staple for individuals of all ages and styles.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Cloths",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 3,
            img: "https://5.imimg.com/data5/UR/ER/MY-10139824/19.jpg",


            secondImg: "https://images.meesho.com/images/products/369905115/uhzcp_512.jpg",
            thirdimage: "https://t3.ftcdn.net/jpg/04/83/25/50/360_F_483255019_m1r1ujM8EOkr8PamCHF85tQ0rHG3Fiqz.jpg",
            fourthImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWnG0w__j2xhz9oZ0PvOrKpMuohIZrtGQIGg&s",
            dicountPrice: "Rs.1000",
            MainPrice: "Rs.1300",
            percentage: "20%",
            collectionName: "Pant",
            description: "It sounds like you're referring to jeans pants, a ubiquitous and versatile clothing item found in nearly every wardrobe around the world. Jeans are renowned for their durability, comfort, and timeless style, making them a go-to choice for casual wear.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Cloths",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 4,
            img: "https://statusquo.in/cdn/shop/products/SQ-RN-20354-BLACK_1.jpg?v=1613540061",


            secondImg: "https://images.unsplash.com/photo-1618517351616-38fb9c5210c6?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHQlMjBzaGlydHxlbnwwfHwwfHx8MA%3D%3D",
            thirdimage: "https://images.unsplash.com/photo-1527719327859-c6ce80353573?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dHNoaXJ0fGVufDB8fDB8fHww",
            fourthImage: "https://m.media-amazon.com/images/I/715gSUR3r5L._AC_UY1100_.jpg",
            dicountPrice: "Rs.400",
            MainPrice: "Rs.500",
            percentage: "20%",
            collectionName: "T-Shirt",
            description: "The t-shirt, a timeless staple of wardrobes worldwide, holds a unique allure that transcends trends and seasons. It's not just a piece of clothing; it's a canvas for individual expression, a symbol of versatility, and an embodiment of simplicity.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Cloths",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 5,
            img: "https://static.fibre2fashion.com/MemberResources/LeadResources/1/2017/8/Buyer/17132419/Images/17132419_0_men-s-shirt.jpg",


            secondImg: "https://5.imimg.com/data5/BR/GE/MY-43691381/formal-shirts-for-men-dark-green-shirt-mens-supplier-500x500.jpg",
            thirdimage: "https://m.media-amazon.com/images/I/71ts9CewHqL._SL1500_.jpg",
            fourthImage: "https://img.freepik.com/premium-photo/portrait-casual-man-standing-with-hand-pocket-gray-wall_171337-83287.jpg",
            dicountPrice: "Rs.600",
            MainPrice: "Rs.800",
            percentage: "20%",
            collectionName: "Shirt",
            description: "The shirt, an essential garment in any wardrobe, embodies understated elegance and versatility. With its sleek and timeless appeal, it seamlessly transitions from casual to formal settings, making it a staple for individuals of all ages and styles.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Cloths",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        // {
        //     id: 6,
        //     img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-1_1.jpg?v=1700194727&width=600",


        //     secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-2.jpg?v=1700194728&width=600",
        //     thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-3_1.jpg?v=1700194727&width=600",
        //     fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-4_1.jpg?v=1700194727&width=600",
        //     dicountPrice: "$25",
        //     MainPrice: "$40",
        //     percentage: "20%",
        //     collectionName: "Matching Sets",
        //     description: "Elevate her everyday wardrobe with our Girls' Floral Matching Set. This set includes a comfortable short-sleeve top and coordinating shorts, both adorned with a vibrant floral print for a playful touch. The soft and stretchy fabric ensures all-day comfort and freedom of movement, while the elastic waistband on the shorts provides a secure fit. Mix and match these pieces with other items in her closet for versatile styling options.",
        //     color: ["Pink", "White", "Yellow"],
        //     SKU: "yoga2",
        //     availability: "In Stock",
        //     categories: "Girls' Fashion",
        //     tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        // },
        // {
        //     id: 7,
        //     img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-1_1.jpg?v=1700194998&width=600",


        //     secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-2_1.jpg?v=1700194998&width=600",
        //     thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-3_1.jpg?v=1700194998&width=600",
        //     fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-4_1.jpg?v=1700194998&width=600",
        //     dicountPrice: "$15",
        //     MainPrice: "$28",
        //     percentage: "30%",
        //     collectionName: "Bottoms",
        //     description: "Add a touch of style to her wardrobe with our Girls' Denim Shorts. These classic denim shorts feature a versatile design that pairs effortlessly with a variety of tops and shoes. The soft and stretchy fabric ensures a comfortable fit, while the adjustable waistband allows for a customizable fit as she grows. Perfect for everyday wear or casual outings, these denim shorts will become a staple in her warm-weather wardrobe.",
        //     color: ["Denim Blue"],
        //     SKU: "yoga3",
        //     availability: "In Stock",
        //     categories: "Girls' Fashion",
        //     tags: ["Girls' Clothing", "Bottoms", "Denim Shorts"]
        // },
        // {
        //     id: 8,
        //     img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-1.jpg?v=1700203068&width=600",
        //     secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-2.jpg?v=1700203068&width=600",
        //     thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-3.jpg?v=1700203068&width=600",
        //     fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-4.jpg?v=1700203068&width=600",
        //     dicountPrice: "$10",
        //     MainPrice: "$18",
        //     percentage: "45%",
        //     collectionName: "Accessories",
        //     description: "Complete her look with our Girls' Bow Headband Set. This set includes three adorable headbands, each featuring a different bow design for versatile styling options. Made from soft and stretchy fabric, these headbands are gentle on her hair and comfortable to wear all day long. Whether she's dressing up for a special occasion or adding a cute accent to her everyday outfit, these bow headbands are the perfect accessory.",
        //     color: ["Pink", "Purple", "White"],
        //     SKU: "yoga4",
        //     availability: "In Stock",
        //     categories: "Girls' Fashion",
        //     tags: ["Girls' Accessories", "Headbands", "Bow Design"]
        // },
        // {
        //     id: 9,
        //     img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-1_1.jpg?v=1700193822&width=600",


        //     secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-2_1.jpg?v=1700193822&width=600",
        //     thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-3_1.jpg?v=1700193822&width=600",
        //     fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-4_1.jpg?v=1700193822&width=600",
        //     dicountPrice: "$20",
        //     MainPrice: "$30",
        //     percentage: "25%",
        //     collectionName: "Tops",
        //     description: "Keep your little one stylish and comfortable with our Girls' Floral Print Tee. Crafted from soft and breathable cotton fabric, this tee features a vibrant floral print that adds a pop of color to her everyday look. The relaxed fit and crew neckline ensure all-day comfort, while the short sleeves make it perfect for layering or wearing alone during warmer weather. Pair it with jeans, shorts, or a skirt for versatile styling options.",
        //     color: ["Blue", "Silver", "Black"],
        //     SKU: "yoga1",
        //     availability: "In Stock",
        //     categories: "Girls' Fashion",
        //     tags: ["Girls' Clothing", "Tops", "Floral Print"]
        // },
        // {
        //     id: 10,
        //     img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-1_1.jpg?v=1700194727&width=600",


        //     secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-2.jpg?v=1700194728&width=600",
        //     thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-3_1.jpg?v=1700194727&width=600",
        //     fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-4_1.jpg?v=1700194727&width=600",
        //     dicountPrice: "$25",
        //     MainPrice: "$40",
        //     percentage: "20%",
        //     collectionName: "Matching Sets",
        //     description: "Elevate her everyday wardrobe with our Girls' Floral Matching Set. This set includes a comfortable short-sleeve top and coordinating shorts, both adorned with a vibrant floral print for a playful touch. The soft and stretchy fabric ensures all-day comfort and freedom of movement, while the elastic waistband on the shorts provides a secure fit. Mix and match these pieces with other items in her closet for versatile styling options.",
        //     color: ["Pink", "White", "Yellow"],
        //     SKU: "yoga2",
        //     availability: "In Stock",
        //     categories: "Girls' Fashion",
        //     tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        // }


    ];


    return (
        <div className='w-full mt-12'>
            <div className='text-center py-2 md:py-5'>
                <h1 className='md:text-3xl text-pretty mb-2 text-2xl font-bold'>Cloths</h1>
                <p className='md:tracking-wide font-meduim text-base md:text-xl	'>Shop the trending products and most buy of the week</p>
            </div>
            <div className='mt-5 p-2'>
    <div className='w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 space-x-3 gap-2'>
        {Data && Data.map((item, index) => (
            // Corrected condition here
            <div className='p-1 md:p-2' key={index}>
                <div className='relative first-img '>
                    <Link className='relative first-img' >
                        <img src={item.img} className='w-full relative transition-all duration-500 ease-linear h-64 md:h-[23rem] object-cover object-center' alt={item.collectionName} />
                        <img src={item.secondImg} className='w-full transition-all duration-500 ease-linear second-img absolute top-0 opacity-0 h-64 md:h-[23rem] object-cover object-center' alt={item.secondImg} />
                    </Link>
                    <div className='buttons'>
                        <ul>
                            <li onClick={() => handleShowQuickView(item, index)}><i className="ri-eye-fill"></i></li>
                            <li><i className="ri-shopping-bag-4-line"></i></li>
                            <li><i className="ri-heart-fill"></i></li>
                        </ul>
                    </div>
                    <div className='product-information text-center'>
                        <p className='text-xl font-medium mb-2'>{item.collectionName}</p>
                        <div className='prices'>
                                        <p className='text-base font-semibold text-[#E51515]'><del className='text-gray-500'>{item.MainPrice}</del> {item.dicountPrice}</p>
                                    </div>
                    </div>
                    <div className='tag'>
                        {item.percentage}
                    </div>
                </div>
            </div>
        ))}
    </div>
</div>

            <QuickView showQuickView={showQuickView} handleClose={handleClose} item={items} />
        </div>)
}

export default WeeksHighlight