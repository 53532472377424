import React, { useEffect, useState } from 'react'
import './NewArrival.css'
import QucikView from './QucikView'
import { Link } from 'react-router-dom'

import { fetchData } from '../../../store/slices/product.slice'
const NewArrival = () => {
    const [showQuickView, setShowQucikView] = useState(false)
    const [items, setItems] = useState(false)


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);


    const handleShowQuickView = (item, index) => {
        setShowQucikView(true)
        console.log("Item", item)
        console.log("index", index)
        setItems(item)

    }
    const handleClose = () => {
        setShowQucikView(false)
    }

    const Data = [
        {
            id: 1,
            img: "https://media.post.rvohealth.io/wp-content/uploads/2021/05/makhana-1200x628-facebook.jpg",


            secondImg: "https://t4.ftcdn.net/jpg/04/58/44/43/360_F_458444319_rpRE7D5p6FZQA3NJ2OsjDX2leBWaw4B4.jpg",
            thirdimage: "https://5.imimg.com/data5/SELLER/Default/2020/9/RX/ZA/TX/63235023/5-suta-phool-makhana-flake.jpg",
            fourthImage: "https://staticimg.amarujala.com/assets/images/2019/11/15/makhana_1573801323.jpeg?w=414&dpr=1.0",
            dicountPrice: "Rs.900",
            MainPrice: "Rs.1200",
            percentage: "25%",
            collectionName: "Makhana",
            description: "Makhana, also known as fox nuts or lotus seeds, are the seeds of the lotus flower plant. They are commonly used in various cuisines, particularly in Indian cuisine, where they are often roasted or fried and seasoned with spices to make a crunchy snack. Makhana is also used in sweets, curries, and other dishes.",
            // color: ["Blue", "Silver", "Black"],
            // SKU: "yoga1",
            availability: "In Stock",
            categories: "Spices",
            // tags: ["Girls' Clothing", "Tops", "Floral Print"]
        },
        {
            id: 2,
            img: "https://media.istockphoto.com/id/965503302/photo/turmeric-powder-and-roots.jpg?s=612x612&w=0&k=20&c=97n0pa3_llc9itGE-nlUJEz5vWwowjA3FF2rNByQmIQ=",


            secondImg: "https://t3.ftcdn.net/jpg/02/70/09/88/360_F_270098811_CzjDTNmRXoQg78CKORlTe8oJDtR3WVTZ.jpg",
            thirdimage: "https://5.imimg.com/data5/SELLER/Default/2023/7/322144231/HY/VX/KT/42218902/organic-turmeric-powder.jpeg",
            fourthImage: "https://nikura.com/cdn/shop/articles/Turmeric-Root-Oil_11zon_375x235_crop_center.jpg?v=1685717837",
            dicountPrice: "Rs.200",
            MainPrice: "Rs.300",
            percentage: "20%",
            collectionName: "Turmeric",
            description: "Turmeric, scientifically known as Curcuma longa, is a bright yellow-orange spice commonly used in cooking, particularly in South Asian and Middle Eastern cuisines. It's derived from the root of the Curcuma longa plant and has been used for centuries both as a spice and as a medicinal herb.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Spices",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 3,
            img: "https://5.imimg.com/data5/SELLER/Default/2023/4/297114051/WN/SZ/QF/109200036/red-chilli-powder.jpeg",


            secondImg: "https://5.imimg.com/data5/SELLER/Default/2022/12/JC/ZZ/SO/6299242/red-chilli-powder.jpg",
            thirdimage: "https://static.toiimg.com/photo/63530651.cms",
            fourthImage: "https://spicerackindia.com/wp-content/uploads/2020/11/Byadagi-Chilli-Powder-FloraFoods.jpg",
            dicountPrice: "Rs.100",
            MainPrice: "Rs.200",
            percentage: "30%",
            collectionName: "Chilli Powder",
            description: "Chili powder is a spice blend made from dried chili peppers that are ground into a fine powder. It's a staple ingredient in many cuisines around the world, particularly in Mexican, Indian, and Thai cooking, where it adds heat, depth of flavor, and vibrant color to dishes.",
            // color: ["Denim Blue"],
            // SKU: "yoga3",
            availability: "In Stock",
            categories: "Spices",
            // tags: ["Girls' Clothing", "Bottoms", "Denim Shorts"]
        },
        {
            id: 4,
            img: "https://m.media-amazon.com/images/I/41JhW9gc0zL._AC_UF1000,1000_QL80_.jpg",
            secondImg: "https://freshclub.co.in/cdn/shop/products/corinader-powder.jpg?v=1660189230",
            thirdimage: "https://hetha.in/cdn/shop/products/coriandert-powder-1_1200x1200.jpg?v=1689956209",
            fourthImage: "https://t3.ftcdn.net/jpg/02/28/74/20/360_F_228742060_zu2eCoQvnYAcI96p2bOW9tpuDzOD2TRs.jpg",
            dicountPrice: "Rs.80",
            MainPrice: "Rs.150",
            percentage: "45%",
            collectionName: "Coriander Powder",
            description: "Coriander powder, also known as ground coriander or coriander seed powder, is a spice made from dried and ground coriander seeds. Coriander is derived from the Coriandrum sativum plant, which is part of the parsley family. Both the seeds and leaves of the coriander plant are used in cooking, but coriander powder specifically refers to the ground seeds.",
            // color: ["Pink", "Purple", "White"],
            // SKU: "yoga4",
            availability: "In Stock",
            // categories: "Girls' Fashion",
            // tags: ["Girls' Accessories", "Headbands", "Bow Design"]
        },
        {
            id: 5,
            img: "https://cdn.britannica.com/59/219359-050-662D86EA/Cumin-Spice.jpg",


            secondImg: "https://5.imimg.com/data5/SELLER/Default/2021/10/XZ/IW/LO/3042133/cumin-seed.jpg",
            thirdimage: "https://cookbook.pfeiffer.net.au/files/cuminSeeds-scaled.jpg",
            fourthImage: "https://static.toiimg.com/thumb/resizemode-4,width-1280,height-720,msid-8366927/8366927.jpg",
            dicountPrice: "Rs.200",
            MainPrice: "Rs.300",
            percentage: "25%",
            collectionName: "Cumin Seeds",
            description: "Cumin seeds, derived from the Cuminum cyminum plant, are a popular spice known for their warm, earthy flavor and aromatic qualities. They are native to the Mediterranean region and have been used in cooking for thousands of years, dating back to ancient civilizations such as the Egyptians, Greeks, and Romans.",
            // color: ["Blue", "Silver", "Black"],
            // SKU: "yoga1",
            availability: "In Stock",
            categories: "Spices",
            // tags: ["Girls' Clothing", "Tops", "Floral Print"]
        },
        {
            id: 6,
            img: "https://www.amar-khamar.com/cdn/shop/products/BlackMustard2.jpg?v=1637861957",


            secondImg: "https://5.imimg.com/data5/SELLER/Default/2022/1/JK/BJ/EI/8491760/mustard-seeds.jpg",
            thirdimage: "https://m.media-amazon.com/images/I/51coKUr87JL._AC_UF1000,1000_QL80_.jpg",
            fourthImage: "https://ricedesk.com/product-images/Mustard-Seeds-Small.jpg/788104000002905349/700x700",
            dicountPrice: "$25",
            MainPrice: "Rs.500",
            percentage: "Rs.600",
            collectionName: "Mustard Seeds",
            description: "Mustard seeds are small, round seeds derived from the mustard plant, which belongs to the Brassicaceae family. These seeds come in different varieties, such as yellow (also known as white), brown, and black, each with its own distinct flavor and culinary uses.",
            // color: ["Pink", "White", "Yellow"],
            // SKU: "yoga2",
            availability: "In Stock",
            categories: "Spices",
            // tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        },
        {
            id: 7,
            img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-1_1.jpg?v=1700194998&width=600",


            secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-2_1.jpg?v=1700194998&width=600",
            thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-3_1.jpg?v=1700194998&width=600",
            fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-set-skort-4_1.jpg?v=1700194998&width=600",
            dicountPrice: "$15",
            MainPrice: "$28",
            percentage: "30%",
            collectionName: "Bottoms",
            description: "Add a touch of style to her wardrobe with our Girls' Denim Shorts. These classic denim shorts feature a versatile design that pairs effortlessly with a variety of tops and shoes. The soft and stretchy fabric ensures a comfortable fit, while the adjustable waistband allows for a customizable fit as she grows. Perfect for everyday wear or casual outings, these denim shorts will become a staple in her warm-weather wardrobe.",
            color: ["Denim Blue"],
            SKU: "yoga3",
            availability: "In Stock",
            categories: "Girls' Fashion",
            tags: ["Girls' Clothing", "Bottoms", "Denim Shorts"]
        },
        {
            id: 8,
            img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-1.jpg?v=1700203068&width=600",
            secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-2.jpg?v=1700203068&width=600",
            thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-3.jpg?v=1700203068&width=600",
            fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Eco-friendlyNon-SlipExercise1-4.jpg?v=1700203068&width=600",
            dicountPrice: "$10",
            MainPrice: "$18",
            percentage: "45%",
            collectionName: "Accessories",
            description: "Complete her look with our Girls' Bow Headband Set. This set includes three adorable headbands, each featuring a different bow design for versatile styling options. Made from soft and stretchy fabric, these headbands are gentle on her hair and comfortable to wear all day long. Whether she's dressing up for a special occasion or adding a cute accent to her everyday outfit, these bow headbands are the perfect accessory.",
            color: ["Pink", "Purple", "White"],
            SKU: "yoga4",
            availability: "In Stock",
            categories: "Girls' Fashion",
            tags: ["Girls' Accessories", "Headbands", "Bow Design"]
        },
        {
            id: 9,
            img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-1_1.jpg?v=1700193822&width=600",


            secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-2_1.jpg?v=1700193822&width=600",
            thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-3_1.jpg?v=1700193822&width=600",
            fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-fast-track-pant-4_1.jpg?v=1700193822&width=600",
            dicountPrice: "$20",
            MainPrice: "$30",
            percentage: "25%",
            collectionName: "Tops",
            description: "Keep your little one stylish and comfortable with our Girls' Floral Print Tee. Crafted from soft and breathable cotton fabric, this tee features a vibrant floral print that adds a pop of color to her everyday look. The relaxed fit and crew neckline ensure all-day comfort, while the short sleeves make it perfect for layering or wearing alone during warmer weather. Pair it with jeans, shorts, or a skirt for versatile styling options.",
            color: ["Blue", "Silver", "Black"],
            SKU: "yoga1",
            availability: "In Stock",
            categories: "Girls' Fashion",
            tags: ["Girls' Clothing", "Tops", "Floral Print"]
        },
        {
            id: 10,
            img: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-1_1.jpg?v=1700194727&width=600",


            secondImg: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-2.jpg?v=1700194728&width=600",
            thirdimage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-3_1.jpg?v=1700194727&width=600",
            fourthImage: "https://cdn.shopify.com/s/files/1/0561/1270/9761/files/Alala-serene-bra-4_1.jpg?v=1700194727&width=600",
            dicountPrice: "$25",
            MainPrice: "$40",
            percentage: "20%",
            collectionName: "Matching Sets",
            description: "Elevate her everyday wardrobe with our Girls' Floral Matching Set. This set includes a comfortable short-sleeve top and coordinating shorts, both adorned with a vibrant floral print for a playful touch. The soft and stretchy fabric ensures all-day comfort and freedom of movement, while the elastic waistband on the shorts provides a secure fit. Mix and match these pieces with other items in her closet for versatile styling options.",
            color: ["Pink", "White", "Yellow"],
            SKU: "yoga2",
            availability: "In Stock",
            categories: "Girls' Fashion",
            tags: ["Girls' Clothing", "Matching Set", "Floral Print"]
        }


    ];


    return (
        <div className='w-full mt-12'>
            <div className='text-center py-2 md:py-5'>
                <h1 className='md:text-3xl text-pretty mb-2 text-2xl font-bold'>Spices</h1>
                <p className='md:tracking-wide font-meduim text-base md:text-xl	'>Shop the trending products and most buy of the week</p>
            </div>
            <div className='mt-5 p-2'>
                <div className='w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 space-x-3 gap-2'>
                    {Data && Data.slice(0,5).map((item, index) => (
                        <div className=' p-1 md:p-2' key={index}>
                            <div className='relative first-img '>
                                <Link className='relative first-img' >

                                    <img src={item.img} className='w-full relative transition-all duration-500 ease-linear  h-64 md:h-[23rem] object-cover object-center' alt={item.collectionName} />
                                    <img src={item.secondImg} className='w-full transition-all duration-500 ease-linear  second-img absolute top-0 opacity-0 h-64 md:h-[23rem] object-cover object-center' alt={item.secondImg} />
                                </Link>
                                <div className='buttons'>
                                    <ul>
                                        <li onClick={() => handleShowQuickView(item, index)}><i className="ri-eye-fill"></i></li>
                                        <li><i className="ri-shopping-bag-4-line"></i></li>
                                        <li><i className="ri-heart-fill"></i></li>

                                    </ul>
                                </div>
                                <div className='product-information text-center'>
                                    <p className='text-xl font-medium mb-2'>{item.collectionName}</p>
                                    <div className='prices'>
                                        <p className='text-base font-semibold text-[#E51515]'><del className='text-gray-500'>{item.MainPrice}</del> {item.dicountPrice}</p>
                                    </div>
                                </div>
                                {/* <h3 className='text-xl font-bold text-red-500'>
                                    <del className='text-gray-300 font-thin'>{item.sizes[0].mainPrice}</del> {item.sizes[0].discountPrice}
                                </h3> */}
                                <div className='tag'>
                                    {item.percentage}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <QucikView showQuickView={showQuickView} handleClose={handleClose} item={items} />
        </div>)
}

export default NewArrival