import React from 'react'
import './Certificates.css'
import icon from './pdf.webp'
import pdf1 from './pdf1.pdf'
import pdf2 from './pdf2.pdf'
import pdf3 from './pdf3.pdf'
import pdf4 from './pdf4.pdf'

function Certificates() {
  return (
    <section className='Certificates-section'>
      <div className="Certificates-container">
        <div className="heading">
            <span>Certificates</span>
        </div>
        <div className="main-container">
            <a target='_blank' href={pdf1} className="col">
                <div className="img">
                    <img src={icon} alt="" />
                </div>
                <div className="pdf-name">
                    <h3>GST CERTIFICATE</h3>
                </div>
            </a>
            <a target='_blank' href={pdf2} className="col">
                <div className="img">
                    <img src={icon} alt="" />
                </div>
                <div className="pdf-name">
                    <h3>UDYAM CERTIFICATE</h3>
                </div>
            </a>
            <a target='_blank' href={pdf3} className="col">
                <div className="img">
                    <img src={icon} alt="" />
                </div>
                <div className="pdf-name">
                    <h3>ISO CERTIFICATE</h3>
                </div>
            </a>
            <a target='_blank' href={pdf4} className="col">
                <div className="img">
                    <img src={icon} alt="" />
                </div>
                <div className="pdf-name">
                    <h3>IEC CERTIFICATE</h3>
                </div>
            </a>
        </div>
      </div>
    </section>
  )
}

export default Certificates
