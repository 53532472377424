import React from 'react'
import './collection.css'
import img from './spces.jpg'
import { Link } from 'react-router-dom'
const EssintalCollection = () => {
    const Data = [
        {
            id: 1,
            img: img,
            collectionName: "Spices",
            href:"/spices"
        },
        {
            id: 2,
            img: "https://m.media-amazon.com/images/I/71ts9CewHqL._SL1500_.jpg",
            collectionName: "Cloths",
            href:"/Leather"
        },
        // {
        //     id: 3,
        //     img: "https://images-cdn.ubuy.co.in/654b698e4f92c8270a115325-lancome-la-vie-est-belle-eau-de-parfum.jpg",
        //     collectionName: "Perfume",
        //     href:"/Perfume"
        // }
    ]
    return (
        <div className='w-full mt-12'>
            <div className='text-center py-2 md:py-5'>
                <h1 className='md:text-3xl text-pretty mb-2 text-2xl font-bold'>The Essential Collection</h1>
                <p className='md:tracking-wide font-meduim text-base md:text-xl	'>Shop the latest products from the most popular collections</p>
            </div>
            <div className='mt-5 p-2'>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 space-x-3 gap-2'>
                    {Data.map((item, index) => (
                        <Link to={`${item.href}`} className=' p-1 md:p-2' key={index}>
                            <div className='relative'>
                                <img src={item.img} className='w-full imgs-collections h-64 md:h-96 object-cover object-center' alt={item.collectionName} />

                                <button className=' whitespace-nowrap collectionbtns'>{item.collectionName}</button>

                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EssintalCollection